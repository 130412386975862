import React, { useEffect, useRef, useState } from "react"
import Layout from "../components/shared-layout";
import { Alert, Box, IconButton, ListItem, ListItemText, Paper, Skeleton, TableBody, TableCell, TableContainer, TableRow, TextField, Tooltip, Typography } from "@mui/material";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import FavoriteIcon from '@mui/icons-material/Favorite';
// @ts-ignore
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import { ITEM_PERFECT_INLINED, PADDING_HORIZONTAL } from "../constants/style";
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import VmButton from "../components/shared-button";
import useStores from "../hooks/use-stores";
import { navigate } from "gatsby";
import DoneIcon from '@mui/icons-material/Done';
import BoltOutlinedIcon from '@mui/icons-material/BoltOutlined';
import VmCalendar from "../components/calendar";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Autoplay, Navigation, Pagination } from "swiper";
import 'swiper/css';
import 'swiper/css/pagination';
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";
import moment from "moment";
import { AddCartItem, AddItemToCartAsGuest, AUDDateFormat, CartItem, CartItemList, GetCartItemNumAsGuest } from "../utilities/cart-item";
import { IsInWishList } from "../utilities/wishlist";
import { ConvertPathNameToLabel, GetLanguage, IsAuthed } from "../utilities/general";
import VmModal from "../components/shared-modal";
import * as queryString from "query-string";
import { IsEmptyStr } from "../utilities/field-validation";
const { productStore, userStore, cartStore, rootStore } = useStores();
const headingStyle = "font-light text-base 2xl:text-lg mt-8 mb-2";
const contentStyle = "font-light text-xs 2xl:text-sm";
const subHeadingStyle = "border-l-4 border-sky-400 pl-4 mt-8 mb-4";
const subContentStyle = "font-light text-sm 2xl:text-base";
const tabStyle = `text-sm sm:text-base hover:bg-sky-400 hover:text-white p-4 transition-all border-r border-white`;

const ProductDetailPage = observer(({ location }: { location: any }) => {
  const { t }: any = useTranslation();
  const [loadPermit, setLoadPermit] = useState<boolean>(false);
  const { productId }: any = queryString.parse(location.search);

  useEffect(() => {
    console.log("???", location.state, productId)
    if (productId) {
      if (productId == 0) navigate(location.state.prevPath);
      else {
        productStore.getProductDetailById(productId, GetLanguage())
          .then(() => {
            setLoadPermit(true);
            productStore.getProductDateRange(productId);
          }).catch(() => {
            rootStore.notify(t('PRODUCT_NOT_RELEASED'), "warning");
            navigate(location.state.prevPath);
          });
      }
    } else navigate('/products/activities');
  }, [location.state]);

  const processingWishList = () => {
    if (!IsAuthed()) rootStore.notify(t('LOGIN_REQUIRED'), "warning");
    else {
      if (!IsInWishList(productStore.productDetail.webIndexId)) {
        userStore.addWishListItemByProductId(productStore.productDetail.webIndexId)
          .then(() => rootStore.notify(t('PRODUCT_ADDED_TO_WISHLIST') + "!", "success"));
      } else {
        userStore.deleteWishListItemByProductId(productStore.productDetail.webIndexId)
          .then(() => rootStore.notify(t('PRODUCT_REMOVED_FROM_WISHLIST') + "!", "success"));
      }
    }
  }

  return (
    <Layout pageName={t('PRODUCT_DETAIL')}
      showBanner={false}
      videoLink={productStore.productDetail && productStore.productDetail.webVideoList.length > 0 ?
        productStore.productDetail.webVideoList[0].url : ""}
      showVideoBanner
      breadCrumb={[
        { to: (location.state && location.state.prevPath) ? location.state.prevPath : "/", label: (location.state && location.state.prevPath) ? location.state.prevPath : t('HOME') },
        { to: "", label: t('PRODUCT_DETAIL') }
      ]}>
      <Box className={PADDING_HORIZONTAL}>

        <Box className="grid grid-cols-3 lg:gap-4 2xl:gap-6">
          {/* Loading */}
          {!productStore.productDetail || productStore.loading ? <>
            <Box><Skeleton variant="rectangular" width="100%" height={150} /></Box>
            <Box className="col-span-2"><Skeleton variant="rectangular" width="100%" height={150} /></Box>
            <Box className="col-span-3">
              <Skeleton variant="rectangular" width="100%" height={300} />
              <Skeleton variant="rectangular" width="100%" height={300} />
            </Box>
            {/* End Loading */}
          </> : <>
            {/* Detail Section */}
            <Box className="col-span-3 sm:col-span-1 min-h-60 h-full">
              <Box className=" bg-white shadow-lg p-2 border">
                {productStore.productDetail.webImageList.length > 0 &&
                  <Swiper
                    modules={[Autoplay, Navigation]}
                    spaceBetween={0}
                    slidesPerView={1}
                    navigation={false}
                    autoplay={{ delay: 5000, disableOnInteraction: false }}
                  >
                    {productStore.productDetail.webImageList.map((image: any, i: number) => (
                      <SwiperSlide key={`image_gallery_${i}`} >
                        <img className="w-full h-full object-cover" src={image.image} />
                      </SwiperSlide>
                    ))}
                  </Swiper>}
              </Box>
              {/* <img src={productStore.productDetail.webThumb} className="w-full h-full object-cover" /> */}
            </Box>
            <Box className="col-span-3 sm:col-span-2">
              <p className="font-bold uppercase lg:text-xl 2xl:text-2xl mt-2">{productStore.productDetail.title}</p>
              <p className="font-light uppercase lg:text-base 2xl:text-lg">{productStore.productDetail.supplierInfo.name}</p>
              <Box sx={ITEM_PERFECT_INLINED}>
                <button
                  disabled={userStore.processingWishList}
                  onClick={processingWishList}
                  className={`${IsInWishList(productStore.productDetail.webIndexId) ? 'text-sky-400' : 'text-gray-500'} mt-2`}>
                  <Box sx={ITEM_PERFECT_INLINED}>
                    {IsInWishList(productStore.productDetail.webIndexId) ? <FavoriteIcon fontSize="small" color="inherit" />
                      : <FavoriteBorderOutlinedIcon fontSize="small" color="inherit" />}
                    <p className={`${contentStyle} ml-1`}>{IsInWishList(productStore.productDetail.webIndexId) ? t('REMOVE_FROM_WISHLIST') : t('ADD_TO_WISHLIST')}</p>
                  </Box>
                </button>
              </Box>
              <Box sx={ITEM_PERFECT_INLINED} className="mt-4">
                <Box sx={ITEM_PERFECT_INLINED}>
                  <LocationOnOutlinedIcon fontSize="small" />
                  <p className={`${contentStyle} ml-1`}>{productStore.productDetail.destination[0].name}</p>
                </Box>
                <Box sx={ITEM_PERFECT_INLINED} className="ml-4">
                  <AccessTimeOutlinedIcon fontSize="small" />
                  <p className={`${contentStyle} ml-1`}>{productStore.productDetail.dtTourLength}</p>
                </Box>
                {productStore.productDetail.instantConfirm && <Box sx={ITEM_PERFECT_INLINED} className="ml-4 text-green-400">
                  <BoltOutlinedIcon fontSize="small" color="inherit" />
                  <p className={`${contentStyle} ml-1`}>{t('INSTANT_CONFIRM')}</p>
                </Box>}
              </Box>
              {/* <p className="font-light lg:text-xs 2xl:text-sm mt-8">{productStore.productDetail.description}</p> */}
              <Box className={`${headingStyle} border-t mt-4 pt-4 uppercase`}>{t('STANDARD_INCLUSIONS')}</Box>

              {productStore.productDetail.included.split('\r\n').map((include: string, i: number) => (
                <ListItem alignItems="flex-start" className="text-green-400" key={`included_list_${i}`}>
                  {(include.includes('.') && !include.includes('•')) && <DoneRoundedIcon color="inherit" fontSize="small" className="mt-1" />}
                  <ListItemText>
                    <p className={`${contentStyle} ml-1 text-black`}>{include}</p>
                  </ListItemText>
                </ListItem>
              ))}
            </Box>
            {/* End Detail Section */}
            {/* Booking Section */}
            {productStore.productDetail && <ProductBookingSection productId={productId} loadPermit={loadPermit} />}
            {/* End Booking Section */}
          </>}
        </Box>
      </Box>
      {/* Tab Section */}
      <ProductDetailSection />
      {/* End Tab Section */}
    </Layout>
  )
});

const ProductBookingSection = observer(({ productId, loadPermit }: { productId: number, loadPermit: boolean }) => {
  const { t }: any = useTranslation();
  const availabilityInfoRef = useRef(null);
  const [ticketQtyDict, setTicketQtyDict] = useState<any[]>([]);
  const [selectedDate, setSelectedDate] = useState<string>("");
  const [totalLegitQty, setTotalLegitQty] = useState<number>(0);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [showAvaResultArea, setShowAvaResultArea] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<string>("");
  const [refresh, setRefresh] = useState<any>([]);

  useEffect(() => {
    // if (productStore.productDateRange.length > 0 && loadPermit) {
    //   setSelectedDate(productStore.productDateRange[0]);
    //   getPriceOptions(productStore.productDateRange[0]);
    // }
  }, [productStore.productDateRange]);

  const onClickedEvent = (date: string) => {
    setErrorMsg("");
    setSelectedDate(date);
    getPriceOptions(date);
    setShowAvaResultArea(false);
  }

  const getPriceOptions = (date: string) => {
    setTotalLegitQty(0);
    productStore.getLowestPrice(productId, date)
      .then((options: any) => {
        if (options.priceJson) {
          let priceOptions = options.priceJson.map((pInfo: any) => ({
            ...pInfo, quantity: 0,
          }));
          setTicketQtyDict(priceOptions);
        }
      });
  }

  const onChangetTicketQuantity = (action: "+" | "-", code: string, quantitySize: number) => {
    setErrorMsg("");
    let quantity = ticketQtyDict.find(t => t.code === code).quantity;
    ticketQtyDict.find(t => t.code === code)
      .quantity = action === "+" ? quantity + 1 : quantity - 1;
    // check total quantity size from user input
    if (quantitySize != 0) {
      if (action === "+") setTotalLegitQty(+totalLegitQty + +quantitySize);
      else setTotalLegitQty(+totalLegitQty - +quantitySize);
    }
    setTicketQtyDict(ticketQtyDict);
    setRefresh([]);
  }

  const onCheckAvailability = () => {
    const qtyMax: number = productStore.priceOptions.qtyMax;
    const qtyMin: number = productStore.priceOptions.qtyMin;
    if (productStore.priceOptions.qtyMin != 0 && totalLegitQty < qtyMin) {
      rootStore.notify(t('INVALID_TICKET_QUANTITY_SELECTED'), "warning");
      setErrorMsg(t('MIN_QTY') + `: ${qtyMin}`);
      return;
    } else if (productStore.priceOptions.qtyMax != 0 && totalLegitQty > qtyMax) {
      rootStore.notify(t('INVALID_TICKET_QUANTITY_SELECTED'), "warning");
      setErrorMsg(t('MAX_QTY') + `: ${qtyMax}`);
      return;
    }
    // @ts-ignore
    if (availabilityInfoRef) availabilityInfoRef.current.scrollIntoView(({ behavior: 'smooth' }));
    productStore.checkAvailability({
      productId,
      departDate: selectedDate,
      language: GetLanguage(),
      quantities: ticketQtyDict.filter((t: any) => t.quantity > 0).map((ticket: any) => ({
        priceType: ticket.code,
        qty: ticket.quantity,
      }))
    }).then(() => setShowAvaResultArea(true))
      .catch(() => rootStore.notify(t('OPERATION_FAILED_PLS_TRY_AGAIN_OR_CONTACT_ADMIN'), 'error'));
  }

  const addItemToCart = (timeslot?: any) => {
    // if (IsAuthed()) {
    //   let cartItem: AddCartItem = {
    //     productId,
    //     departDate: selectedDate,
    //     language: GetLanguage(),
    //     qty: productStore.avaInfo.quantities.filter((q: any) => q.qty != 0).map((ticket: any) => ({
    //       priceType: ticket.priceType,
    //       qty: ticket.qty,
    //     }))
    //   }
    //   if (timeslot) {
    //     cartItem.timeId = timeslot.id;
    //     cartItem.time = timeslot.name;
    //   }
    //   cartStore.addCartItem(cartItem).then(() => {
    //     setShowAvaResultArea(false);
    //     rootStore.notify(t('PRODUCT_HAS_ADDED_TO_YOUR_CART'), 'success');
    //     cartStore.getCartItemNumber();
    //     setShowModal(productStore.productDetail.title);
    //   });
    // } else {
    //   let cartItem: CartItem = {
    //     productId,
    //     priceName: productStore.productDetail.priceName,
    //     processingTime: productStore.productDetail.processingTime,
    //     productName: productStore.productDetail.title,
    //     quantities: productStore.avaInfo.quantities.map((info: any) => ({
    //       priceType: info.priceType,
    //       quantity: info.qty,
    //       unitPrice: info.unitPrice,
    //       priceName: productStore.priceOptions.priceJson.find((p: any) => p.code === info.priceType).name
    //     })),
    //     totalQuantity: productStore.avaInfo.quantities.map((info: any) => ({
    //       priceType: info.priceType,
    //       quantity: info.qty,
    //       unitPrice: info.unitPrice,
    //       priceName: productStore.priceOptions.priceJson.find((p: any) => p.code === info.priceType).name
    //     })),
    //     startDate: selectedDate + "T00:00:00",
    //     subTotal: productStore.avaInfo.totalPrice,
    //     webImage: productStore.productDetail.webThumb,
    //     isInstantConfirm: productStore.productDetail.instantConfirm,
    //     scheduleTime: timeslot.name ? timeslot.name : "",
    //     time: timeslot.id ? timeslot.id : 0,
    //   };
    //   console.log(cartItem);
    //   AddItemToCartAsGuest(cartItem);
    //   rootStore.notify(t('PRODUCT_HAS_ADDED_TO_YOUR_CART'), 'success');
    //   setShowAvaResultArea(false);
    //   GetCartItemNumAsGuest();
    //   setShowModal(productStore.productDetail.title);
    // }

    let cartItem: AddCartItem = {
      productId,
      departDate: selectedDate,
      language: GetLanguage(),
      qty: productStore.avaInfo.quantities.filter((q: any) => q.qty != 0).map((ticket: any) => ({
        priceType: ticket.priceType,
        qty: ticket.qty,
      }))
    }
    console.log();

    if (productStore.avaInfo.isApi && timeslot) {
      cartItem.timeId = timeslot.id;
      cartItem.time = timeslot.name;
    }
    cartStore.addCartItem(cartItem).then((item: any) => {
      setShowAvaResultArea(false);
      rootStore.notify(t('PRODUCT_HAS_ADDED_TO_YOUR_CART'), 'success');
      if (IsAuthed()) cartStore.getCartItemNumber();
      else {
        AddItemToCartAsGuest(item);
        GetCartItemNumAsGuest();
      }
      setShowModal(productStore.productDetail.title);
    }).catch(() => rootStore.notify(t('OPERATION_FAILED_PLS_TRY_AGAIN_OR_CONTACT_ADMIN'), 'error'));
  }

  return (
    <>
      {productStore.productDetail &&
        <Box className="col-span-3 border-t-2 border-dashed border-gray-300 my-8">
          <Box className="grid grid-cols-2 gap-6 gap-4 py-8">
            {/* price display only for small screen */}
            <Box className="block sm:hidden col-span-2 border-4 border-orange-500 border-4 text-orange-500 text-center p-2 mb-2">
              <p className="font-light text-xl 2xl:text-2xl uppercase">{t('FROM')} AUD {t('FROM_B')}</p>
              <p className="text-3xl 2xl:text-4xl font-bold"><span className="text-base 2xl:text-lg font-light">$</span>{productStore.productDetail.price} {t('FROM_B')}</p>
            </Box>
            {productStore.loadingDateRange ? <Skeleton variant="rectangular" width="100%" height={300} />
              : <Box className="col-span-2 sm:col-span-1 shadow-lg border p-8"><VmCalendar onClickEvent={onClickedEvent} /></Box>}
            <Box className="col-span-2 sm:col-span-1">
              <Box className="hidden sm:block border-4 border-orange-500 border-4 text-orange-500 text-center p-2 mb-4">
                <p className="font-light text-xl 2xl:text-2xl uppercase">{t('FROM')} AUD {t('FROM_B')}</p>
                <p className="text-3xl 2xl:text-4xl font-bold"><span className="text-base 2xl:text-lg font-light">$</span>{productStore.productDetail.price} {t('FROM_B')}</p>
              </Box>
              <Box className="border-4 border-green-500">
                <p className="bg-green-500 text-white font-light px-2 py-1 uppercase">{t('SELECTED_TRAVEL_DATE')}</p>
                <p className="text-center p-2 sm:text-lg 2xl:text-xl font-bold uppercase">{!IsEmptyStr(selectedDate) ? moment(selectedDate).format(AUDDateFormat) : t('NO_SELECTED')}</p>
              </Box>
              {/* <TextField
                label={t('TRAVEL_DATE')}
                variant="outlined"
                disabled
                onChange={(event) => setSelectedDate(event?.target.value)}
                type="date"
                sx={{ width: "100%", borderColor: "green" }}
                value={selectedDate}
                InputLabelProps={{ shrink: true }}
              /> */}
              {productStore.loadingDateRange || productStore.loadingPriceOptions ? <><Skeleton variant="rectangular" width="100%" height={50} sx={{ marginTop: 2 }} /><Skeleton variant="rectangular" width="100%" height={50} sx={{ marginTop: 2 }} /><Skeleton variant="rectangular" width="100%" height={50} sx={{ marginTop: 2 }} /></>
                : ticketQtyDict.length == 0 ? <p className="text-center mt-8">{t('NO_TICKET_OPTIONS_AVA')}</p>
                  : <>
                    {ticketQtyDict.map((ticket: any, i: number) => (
                      <Box key={`ticket_option_${i}`} sx={ITEM_PERFECT_INLINED} className="flex justify-between my-2">
                        <p className={`pl-2 ${ticket.quantity > 0 && 'text-sky-400'}`}>{ticket.name} <span className="text-xs text-gray-400 font-light">{ticket.remark}</span></p>
                        <Box sx={ITEM_PERFECT_INLINED}>
                          <IconButton disabled={ticket.quantity == 0} className="mx-2" onClick={() => onChangetTicketQuantity("-", ticket.code, ticket.totalQty)}>
                            <RemoveCircleIcon color="inherit" />
                          </IconButton>
                          <Typography>{ticket.quantity}</Typography>
                          <IconButton className="ml-2" onClick={() => onChangetTicketQuantity("+", ticket.code, ticket.totalQty)}>
                            <AddCircleIcon color="inherit" />
                          </IconButton>
                        </Box>
                      </Box>
                    ))}
                    <Box ref={availabilityInfoRef} className="flex justify-center my-4 py-4 border-t border-b">
                      <VmButton
                        className="px-14 py-4" onClick={onCheckAvailability}
                        disabled={ticketQtyDict.filter((t: any) => t.quantity > 0).length == 0 || totalLegitQty == 0}>
                        {t('CHECK_AVAILABILITY')}
                      </VmButton>
                    </Box>
                    {errorMsg !== "" && <p className="text-red-500 text-center p-2">{errorMsg}</p>}
                  </>}
            </Box>
          </Box>
          {productStore.checkingAva || cartStore.loading ? <Skeleton className="col-span-2 sm:col-span-1" variant="rectangular" width="100%" height={50} sx={{ marginTop: 2 }} />
            : (productStore.avaInfo && showAvaResultArea) && <Box className="shadow-lg border">
              <Box className="grid grid-cols-1 sm:grid-cols-4 gap-4 p-4">
                <Box className="col-span-3">
                  <p className="font-bold text-xl 2xl:text-2xl">{productStore.productDetail.title}</p>
                  <p className="font-bold text-xl 2xl:text-2xl my-2">{t('DEPARTURE_DATE')}: {moment(selectedDate).format(AUDDateFormat)}</p>
                  {productStore.avaInfo.quantities.filter((q: any) => q.qty != 0)
                    .map((qty: any, i: number) => (
                      <p key={`qty_ava_${i}`} className="text-base 2xl:text-lg text-gray-400 font-light">{qty.qty} {qty.priceName} x ${qty.subTotal.toFixed(2)}</p>
                    ))}
                </Box>
                <Box className={`sm:text-right relative`}>
                  <p className="text-red-500 text-3xl 2xl:text-4xl font-bold">A${productStore.avaInfo.totalPrice.toFixed(2)}</p>
                  {(productStore.avaInfo.availability.length == 0 && productStore.avaInfo.available) && <Box className="w-full"><VmButton disabled={!productStore.avaInfo.available} onClick={() => addItemToCart()} className="sm:absolute right-0 bottom-0 w-full">{productStore.avaInfo.available ? t('ADD_TO_CART') : t('UNAVAILABLE')}</VmButton></Box>}
                </Box>
                {(productStore.avaInfo.availability.length == 0 && !productStore.avaInfo.available) && <p className="text-red-500 font-bold mb-4 col-span-3 sm:col-span-4">{t('NO_AVAILABLE_PRICE_OPTIONS_PLEASE_RETRY_WITH_ANOTHER_DATE')}</p>}
                {productStore.avaInfo.availability.length > 0 && <Box className="col-span-4">
                  {productStore.avaInfo.availability.filter((ava: any) => ava.qty > 0).length == 0 ? <p className="text-red-500 font-bold mb-4">{t('NO_AVAILABLE_PRICE_OPTIONS_PLEASE_RETRY_WITH_ANOTHER_DATE')}</p>
                    : productStore.avaInfo.availability.map((ava: any, i: number) => (
                      <VmButton key={`ava_time_select_${i}`} disabled={ava.qty < 1 || totalLegitQty > ava.qty} bg={ava.qty > 0 || totalLegitQty > ava.qty ? 'bg-sky-400' : 'bg-gray-400'} borderColor={ava.qty > 0 || totalLegitQty > ava.qty ? 'border-sky-400' : 'border-gray-400'} onClick={() => addItemToCart(ava)} className="mr-2 mb-2">
                        {ava.qty > 0 && totalLegitQty <= ava.qty ? t('ADD_TO_CART')
                          : ava.qty <= 0 ? t('UNAVAILABLE')
                            : `${t('ONLY')} ${ava.qty} ${t('LEFT')}`} ({moment(ava.name.split(' ').length > 1 ? ava.name.split(' ')[1] : ava.name, 'HH:mm').format('hh:mm A')})
                      </VmButton>
                    ))}
                </Box>}
              </Box>
            </Box>}

        </Box>}

      <VmModal width="60%" openModal={showModal !== ""} onClose={() => setShowModal("")}>
        <Box className="px-4 py-8">
          <Box sx={ITEM_PERFECT_INLINED} className="justify-center">
            <span className="text-sky-500"><DoneIcon fontSize="large" color="inherit" /></span>
            <p className="text-center sm:text-left text-base sm:text-2xl 2xl:text-3xl font-bold uppercase">{t('YOUR_ITEM_HAS_BEEN_ADDED')}</p>
          </Box>
          <p className="text-sm sm:text-lg 2xl:text-xl font-light text-sky-500 text-center mt-4">{showModal}</p>

          <Box sx={ITEM_PERFECT_INLINED} className="text-center sm:text-left block sm:flex justify-center mt-8">
            <VmButton className="mr-0 mb-2 sm:mb-0 sm:mr-4" borderColor="border-sky-400" color="text-sky-400" bg="bg-transparent" onClick={() => setShowModal("")}>{t('CONTINUE_SHOPPING')}</VmButton>
            {cartStore.cartItemNum > 1 ? <VmButton onClick={() => navigate('/cart')}>{t('PROCEED_TO_CART')}</VmButton>
              : <VmButton onClick={() => navigate('/cart/check-out')}>{t('PROCEED_TO_CHECKOUT')}</VmButton>}
          </Box>
        </Box>
      </VmModal>
    </>
  )
})

const ProductDetailSection = observer(() => {
  const { t }: any = useTranslation();
  const [activeTab, setActiveTab] = useState<"KeyFacts" | "Overview" | "Policy" | "Reviews">("KeyFacts");
  return (
    <>
      {productStore.productDetail && <>
        <Box className="shadow-lg border-t flex">
          <Box className={PADDING_HORIZONTAL}>
            <button className={`${tabStyle} ${activeTab === "KeyFacts" && 'bg-sky-400 text-white'}`} onClick={() => setActiveTab("KeyFacts")}>{t('KEY_FACTS')}</button>
            <button className={`${tabStyle} ${activeTab === "Overview" && 'bg-sky-400 text-white'}`} onClick={() => setActiveTab("Overview")}>{t('OVERVIEW')}</button>
            <button className={`${tabStyle} ${activeTab === "Policy" && 'bg-sky-400 text-white'}`} onClick={() => setActiveTab("Policy")}>{t('POLICY')}</button>
            <button className={`${tabStyle} ${activeTab === "Reviews" && 'bg-sky-400 text-white'}`} onClick={() => setActiveTab("Reviews")}>{t('YOTPO_REVIEWS')}</button>
          </Box>
        </Box>
        <Box className="bg-white">
          <Box className={PADDING_HORIZONTAL}>
            <Box className="grid grid-cols-1 sm:grid-cols-2 mt-4 overflow-hidden">
              {activeTab === "KeyFacts" ? <>
                <Box>
                  <Box className={subHeadingStyle}>{t('INCLUDED')}</Box>
                  {productStore.productDetail.included.split('\r\n').map((include: string, i: number) => (
                    <ListItem alignItems="flex-start" className="text-green-400" key={`detail_sect_included_list_${i}`}>
                      {(include.includes('.') && !include.includes('•')) && <DoneRoundedIcon color="inherit" fontSize="small" className="mt-1" />}
                      <ListItemText>
                        <p className={`${contentStyle} ml-1 text-black`}>{include}</p>
                      </ListItemText>
                    </ListItem>
                  ))}
                  <Box className={subHeadingStyle}>{t('EXCLUDED')}</Box>
                  {productStore.productDetail.excluded.split('\r\n').map((exclude: string, i: number) => (
                    <ListItem alignItems="flex-start" className="text-red-400" key={`detail_sect_excluded_list_${i}`}>
                      <CloseRoundedIcon color="inherit" fontSize="small" className="mt-1" />
                      <ListItemText>
                        <p className={`${contentStyle} ml-1 text-black`}>{exclude}</p>
                      </ListItemText>
                    </ListItem>
                  ))}
                  <Box className={subHeadingStyle}>{t('DESTINATION')}</Box>
                  {productStore.productDetail.destination.map((dest: any, i: number) => (
                    <li className={`${subContentStyle}`} key={`dest_${i}`}>{dest.name}</li>
                  ))}
                  <Box className={subHeadingStyle}>{t('DEPARTURE_POINT')}</Box>
                  {productStore.productDetail.departurePoint.split('\r\n').map((info: string, i: number) => (
                    <p className={`${subContentStyle} mb-2`} key={`dp_${i}`}>{info}</p>
                  ))}
                </Box>
                <Box>
                  <Box className={subHeadingStyle}>{t('HOW_TO_GET_THERE')}</Box>
                  {productStore.productDetail.howToGetThere.split('\r\n').map((htgt: string, i: number) => (
                    <p className={`${subContentStyle} mb-2`} key={`htgt_list_${i}`}>{htgt}</p>
                  ))}
                  {productStore.productDetail.operatingHours && <>
                    <Box className={subHeadingStyle}>{t('OPERATING_HOURS')}</Box>
                    <TableBody component={Paper}>
                      {productStore.productDetail.operatingHours.map((hourInfo: any, i: number) => (
                        <TableRow key={`operating_hour_${i}`}>
                          <TableCell>{hourInfo.season}</TableCell>
                          <TableCell>{hourInfo.time}</TableCell>
                          <TableCell>{hourInfo.last}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </>}
                  {productStore.productDetail.checkInRequirements !== "" && <Box className={subHeadingStyle}>{t('CHECK_IN_REQUIREMENT')}</Box>}
                  <p className={`${subContentStyle} mb-2`}>{productStore.productDetail.checkInRequirements}</p>
                  {productStore.productDetail.whatToBringAndWear !== "" && <Box className={subHeadingStyle}>{t('WHAT_TO_BRING_WEAR')}</Box>}
                  <ul className={`${subContentStyle} mb-2`}>{productStore.productDetail.whatToBringAndWear.split('● ')
                    .map((label: string, i: number) => (
                      <li key={`what_to_bring_and_wear_${i}`} className={i == 0 ? "hidden" : ""}>{label}</li>
                    ))}</ul>
                </Box>
                <Box className="sm:col-span-2 border-t mt-4">
                  <Box className={subHeadingStyle}>{t('OTHER_INFO')}</Box>
                  {productStore.productDetail.otherInfo.split('\r\n').map((info: string, i: number) => (
                    <ListItem alignItems="flex-start" key={`detail_sect_other_info_list_${i}`}>
                      <ListItemText>
                        <p className={`${contentStyle} text-black`}>{info}</p>
                      </ListItemText>
                    </ListItem>
                  ))}
                </Box>
              </> : activeTab === "Overview" ? <>
                <Box className="col-span-2">
                  <Box className={subHeadingStyle}>{t('OVERVIEW')}</Box>
                  <p className={`${subContentStyle} mb-2`}>{productStore.productDetail.description}</p>
                  {productStore.productDetail.webImageList.length > 0 &&
                    <Swiper
                      modules={[Autoplay, Navigation, Pagination]}
                      spaceBetween={0}
                      slidesPerView={1}
                      pagination={{ clickable: true }}
                      navigation={true}
                      autoplay={{ delay: 5000, disableOnInteraction: false }}
                    >
                      {productStore.productDetail.webImageList.map((image: any, i: number) => (
                        <SwiperSlide key={`image_gallery_${i}`}>
                          <p className="border-l-4 border-white z-10 absolute pl-4 left-0 bottom-0 m-10 translate-x-neg-45 translate-y-neg-45 text-white lg:text-xs 2xl:text-sm">{image.en}</p>
                          <img className="w-full lg:h-60vh 2xl:h-60vh" style={{ objectFit: 'cover', filter: "brightness(60%)" }} src={image.image} />
                        </SwiperSlide>
                      ))}
                    </Swiper>}
                  <Box className={subHeadingStyle}>{t('ITINERARY')}</Box>
                  <div className={`${subContentStyle} overview-area`} style={{ minHeight: 200 }} dangerouslySetInnerHTML={{ __html: productStore.productDetail.itineraryDescription }} />
                </Box>
              </> : activeTab === "Policy" ? <Box className="col-span-2">
                <Box className={subHeadingStyle}>{t('POLICY')}</Box>
                <ul className={`${subContentStyle} mb-2`}>
                  {productStore.productDetail.freeCancellation.split('● ')
                    .map((label: string, i: number) => (
                      <li key={`free_cancellation_${i}`} className={i == 0 ? "hidden" : ""}>{label}</li>
                    ))}
                </ul>
              </Box> : <></>}
            </Box>
          </Box>
        </Box>
      </>}
    </>
  )
});

export default ProductDetailPage;
